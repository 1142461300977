<template>
  <div class="menu-wrapper">
    <div class="menu-wrapper">
      <transition name="fade" mode="out-in">


        <div class="modal_deep_dive modal_deep_dive--bgImage" v-if="activeCard.type ==='webflow_01'" key="1">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_01">
            <!-- <div class="webflow_01--row"> -->
              <div class="webflow_01--left_side">
                <div class="left_side--top">
                  <p v-html="activeCard.headline_top"></p>
                  <h1 v-html="activeCard.headline"></h1>
                </div>
                <div class="buttonwrap">
                  <HaefeleButton
                    @click="requestLiveChat()"
                    :title="$t('views.buttons.contact_sales')"
                    :icon="'Icon-Chat.svg'"
                  />
                </div>
              </div>
              <div class="webflow_01--center"></div>
              <div class="webflow_01--right_side"></div>
            <!-- </div> -->

            <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
              <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
            </div>
          </div>
        </div>

        <div class="modal_deep_dive fullscreen-bg-02" v-if="activeCard.type ==='webflow_01-0'" key="2">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_01">
            <!-- <div class="webflow_01--row"> -->
              <div class="webflow_01--left_side">
                <div class="left_side--top">
                  <p v-html="activeCard.headline_top"></p>
                  <h1 v-html="activeCard.headline"></h1>
                </div>
                <div class="buttonwrap">
                  <HaefeleButton
                    @click="requestLiveChat()"
                    :title="$t('views.buttons.contact_sales')"
                    :icon="'Icon-Chat.svg'"
                  />
                </div>
              </div>
              <div class="webflow_01--center"></div>
              <div class="webflow_01--right_side"></div>
            <!-- </div> -->

            <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
              <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
            </div>
          </div>
        </div>

        <div class="modal_deep_dive fullscreen-bg-03" v-if="activeCard.type ==='webflow_01-1'" key="3">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_01-1">
              <!-- <div class="webflow_01--left_side"> -->
                <div class="left_side--top">
                  <p v-html="activeCard.headline_top"></p>
                  <h1 v-html="activeCard.headline"></h1>
                </div>
                <div class="right_side--top">
                  <p v-html="activeCard.description"></p>
                </div>
              <!-- </div> -->
          </div>
            <div class='webflow_01-1--button'>
              <div class="buttonwrap">
               <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
              <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
            </div>
        </div>

        <div class="modal_deep_dive fullscreen-bg-04" v-if="activeCard.type ==='webflow_01-2'" key="4">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_01-1 webflow_01-1--grid">
              <!-- <div class="webflow_01--left_side"> -->
                <div class="left_side--top">
                  <h1 v-html="activeCard.headline_1"></h1>
                  <p v-html="activeCard.description_1"></p>
                </div>
                <div class="center--top">
                  <h1 v-html="activeCard.headline_2"></h1>
                  <p v-html="activeCard.description_2"></p>
                </div>
                <div class="right_side--top">
                  <h1 v-html="activeCard.headline_3"></h1>
                  <p v-html="activeCard.description_3"></p>
                </div>
              <!-- </div> -->
          </div>
            <div class='webflow_01-1--button'>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                                :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
              <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
            </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_05'" key="5">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_05">
            <div class="left_side">
              <div>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
              </div>
              <!-- <img class="webflow_05--icon" src="~assets/images/Slido/08_Flaechenbuendig-grafic@2x.png" alt=""> -->
              <div class="buttonwrap">

                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
           <div class="right_side">
              <div class="images__grid">
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/05_Module-1@2x.jpg" alt="">
                  <p v-html="activeCard.image_description_1"></p>
                </div>
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/05_Module-2@2x.jpg" alt="">
                  <p v-html="activeCard.image_description_2"></p>
                </div>
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/05_Module-3@2x.jpg" alt="">
                  <p v-html="activeCard.image_description_3"></p>
                </div>
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/05_Module-4@2x.jpg" alt="">
                  <p v-html="activeCard.image_description_4"></p>
                </div>
                
              </div>
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_05-1'" key="6">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_05">
            <div class="left_side">
              <div>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.tag"></p>
              </div>
              <p>Verfügbare Termine</p>
              <img class="webflow_05--icon" src="~assets/images/Akademie/06_Schnupperangebot-Tabelle.svg" alt="">
              <div class="buttonwrap">

                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="images__grid">
                <div>
                                <img class='images__grid--image' src="~assets/images/Akademie/05_Module-1@2x.jpg" alt="">
                </div>
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/05_Module-2@2x.jpg" alt="">
                </div>
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/05_Module-3@2x.jpg" alt="">
                </div>
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/05_Module-4@2x.jpg" alt="">
                </div>
                
              </div>
            </div>
          </div>
          <div class='angebot'>
            <img src="~assets/images/Akademie/06_Schnupperangebot-4-Module.svg" alt="">
            <div>
              <p>Teilnahmegebühr</p>
              <img src="~assets/images/Akademie/06_Schnupperangebot-Preis.svg" alt="">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>


        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_07'" key="7">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_07">
             <div class="left_side">
              <div>
                <h5 v-html="activeCard.headline_top"></h5>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
              </div>
              <div class="webflow_07--details">
                <div class="details__column">
                  <p>Lernformat</p>
                  <p> <span class="point__arrow"> > </span> <span v-html="activeCard.courseType"></span></p>
                </div>
                <div class="details__column">
                  <p>Dauer</p>
                  <p> <span class="point__arrow"> > </span> <span v-html="activeCard.duration"></span></p>
                </div>
              </div>
              <p>Verfügbare Termine</p>
              <img class="webflow_05--icon" src="~assets/images/Akademie/07_Lichtwirkung-Tabelle.svg" alt="">
              <div class="buttonwrap">

                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="middle__wrapper">
              <div class="middle__list">
                <p>LERNZIEL<br/> der Teilnehmer</p>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> bekommt ein Grundverständnis zum Thema Lichtwirkung </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> versteht welche Rahmenbedingungen Einfluss auf die Lichtwirkung haben </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> bekommt Tipps und Tricks für die Gestaltung mit Licht in Möbel und Einrichtungen</p></div>
                 
                            </div>
              <div class="middle__list">
                <p>INHALTE</p>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Anforderungen an die Lichtwirkung  </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Wahrnehmung und Wirkung von Licht </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Lichtfarbe</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Abstrahlwinkel  </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Einbauposition</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Oberﬂäche</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Schatten, lineares Licht</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Positive und negative Anwendungsbeispiele  </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Behaglichkeit - Beleuchtungsstärke und Lichtfarbe</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Spot & Linearbeleuchtung Farbtemperatur</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> RGB, Multiweiß</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Licht als aktives Verkaufsargument</p></div>
              </div>
            </div>

            <div class="right_side">
              <img class="webflow_07--image" src="~assets/images/Akademie/07_Lichtwirkung@2x.jpg" alt="">
            </div>
          </div>
          <div class='angebot'>
            <div>
              <p>Teilnahmegebühr</p>
              <img src="~assets/images/Akademie/07_Lichtwirkung-Preis.svg" alt="">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_07-1'" key="8">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_07">
             <div class="left_side">
              <div>
                <h5 v-html="activeCard.headline_top"></h5>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
              </div>
              <div class="webflow_07--details">
                <div class="details__column">
                  <p>Lernformat</p>
                  <p> <span class="point__arrow"> > </span> <span v-html="activeCard.courseType"></span></p>
                </div>
                <div class="details__column">
                  <p>Dauer</p>
                  <p> <span class="point__arrow"> > </span> <span v-html="activeCard.duration"></span></p>
                </div>
              </div>
              <p>Verfügbare Termine</p>
              <img class="webflow_05--icon" src="~assets/images/Akademie/08_Lichttechnik-Tabelle.svg" alt="">
              <div class="buttonwrap">

                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="middle__wrapper">
              <div class="middle__list">
                <p>LERNZIEL<br/> der Teilnehmer</p>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> kann die Technischen Datenblätter lesen und deren Inhalte verstehen </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> kennt die unterschiedlichen Beleuchtungssysteme und deren Vor- und Nachteile</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> versteht die Qualitätsmerkmale der Beleuchtungssysteme und ist über die Vorschriften, Prüfzeichen und Normen </p></div>
                     
              </div>
              <div class="middle__list">
                <p>INHALTE</p>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Grundbegriffe und Maßeinheiten der Beleuchtungstechnik </p></div>
                                <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Basiswissen LED Beleuchtung</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Lichtspektrum Tageslicht, Glühlampe, Leuchtstofflampen, LED </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Anforderungen an die Beleuchtung DIN EN 12464 </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Lichtqualität</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Lebensdauer</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Lichtverteilungskurve</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Messung der Beleuchtungsstärke </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> IP Schutz</p></div>
              </div>
            </div>

            <div class="right_side">
              <img class="webflow_07--image" src="~assets/images/Akademie/08_Lichttechnik@2x.jpg" alt="">
            </div>
          </div>
          <div class='angebot'>
            <div>
              <p>Teilnahmegebühr</p>
              <img src="~assets/images/Akademie/08_Lichttechnik-Preis.svg" alt="">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_07-2'" key="9">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_07">
             <div class="left_side">
              <div>
                <h5 v-html="activeCard.headline_top"></h5>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
              </div>
              <div class="webflow_07--details">
                <div class="details__column">
                  <p>Lernformat</p>
                  <p> <span class="point__arrow"> > </span> <span v-html="activeCard.courseType"></span></p>
                </div>
                <div class="details__column">
                  <p>Dauer</p>
                  <p> <span class="point__arrow"> > </span> <span v-html="activeCard.duration"></span></p>
                </div>
              </div>
              <p>Verfügbare Termine</p>
              <img class="webflow_05--icon" src="~assets/images/Akademie/09_Steuerung-Tabelle.svg" alt="">
              <div class="buttonwrap">

                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="middle__wrapper">
              <div class="middle__list">
                <p>LERNZIEL<br/> der Teilnehmer</p>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> hat Kenntnis über die unterschiedlichen Schaltsysteme  </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> kennt Hardware und Software für Smartphone Steuerung </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> erlernt das Erstellen und effektive Nutzen von Lichtszenen </p></div>
                 
              </div>
              <div class="middle__list">
                <p>INHALTE</p>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Mechanische Schalter </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Sensorschalter</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Funksteuerungen </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Dimmen, dimm to warm Funktionsweise(n), Vor- u. Nachteile </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Schaltbeispiele</p></div>
                                <div class="middle__list--point"><span class="point__arrow"> > </span> <p> „Smarte Möbel“ Steuerung von Möbeln per Smartphone</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Schnittstellen zur Hausinstallation</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Datensicherheit </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Tageslichtregelung</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Dynamische Beleuchtung und Lichtszenen</p></div>
              </div>
            </div>

            <div class="right_side">
              <img class="webflow_07--image" src="~assets/images/Akademie/09_Steuerung@2x.jpg" alt="">
            </div>
          </div>
          <div class='angebot'>
            <div>
              <p>Teilnahmegebühr</p>
              <img src="~assets/images/Akademie/09_Steuerung-Preis.svg" alt="">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>
        
        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_07-3'" key="10">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_07">
             <div class="left_side">
              <div>
                <h5 v-html="activeCard.headline_top"></h5>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
              </div>
              <div class="webflow_07--details">
                <div class="details__column">
                  <p>Lernformat</p>
                  <p> <span class="point__arrow"> > </span> <span v-html="activeCard.courseType"></span></p>
                </div>
                <div class="details__column">
                  <p>Dauer</p>
                  <p> <span class="point__arrow"> > </span> <span v-html="activeCard.duration"></span></p>
                </div>
              </div>
              <p>Verfügbare Termine</p>
              <img class="webflow_05--icon" src="~assets/images/Akademie/10_Instaöllation-Tabelle.svg" alt="">
              <div class="buttonwrap">

                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="middle__wrapper">
              <div class="middle__list">
                <p>LERNZIEL<br/> der Teilnehmer</p>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> kann die passende Montageart auswählen und alle Komponenten fachgerecht platzieren und miteinander verbinden </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> kennt die Vorschriften für die unterschiedlichen Anwendungs-/ Einsatzbereiche</p></div>
                 
              </div>
              <div class="middle__list">
                <p>INHALTE</p>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Montagearten </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Kühlung/Belüftung </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Verlegung der Kabel </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Kabellängen, Verteiler, Erweiterungen </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Platzierung der Netzteile</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Bohrlehren und Montagehilfen</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Anforderungen an die Steuerung/Schaltung</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Flächenlicht / hinterleuchtete Materialien </p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Auswahlkriterien für das passende Beleuchtungssystem</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Normen</p></div>
                                <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Prüfzeichen</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Feuchträume</p></div>
                  <div class="middle__list--point"><span class="point__arrow"> > </span> <p> Entsorgung</p></div>
              </div>
            </div>

            <div class="right_side">
              <img class="webflow_07--image" src="~assets/images/Akademie/10_Installation@2x.jpg" alt="">
            </div>
          </div>
          <div class='angebot'>
            <div>
              <p>Teilnahmegebühr</p>
              <img src="~assets/images/Akademie/10_Instaöllation-Preis.svg" alt="">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_05-2'" key="11">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_05">
            <div class="left_side">
              <div>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.tag"></p>
              </div>
              <p>Verfügbare Termine</p>
              <img class="webflow_05--icon" src="~assets/images/Akademie/11_Kombipaket-Tabelle.svg" alt="">
              <div class="buttonwrap">

                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="images__grid">
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/11_Kombipaket-1.jpg" alt="">
                </div>
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/11_Kombipaket-2.jpg" alt="">
                </div>
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/11_Kombipaket-3.jpg" alt="">
                </div>
                <div>
                  <img class='images__grid--image' src="~assets/images/Akademie/11_Kombipaket-4.jpg" alt="">
                </div>
                
              </div>
            </div>
          </div>
          <div class='angebot'>
            <div>
              <p>Teilnahmegebühr</p>
              <img src="~assets/images/Akademie/11_Kombipaket-Preis.svg" alt="">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_05-3'" key="12">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_05">
            <div class="left_side">
              <div>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
                <p>Hier gibt es weitere Informationen zur Häfele Akademie: <a href="https://www.haefele.de/akademie">www.haefele.de/akademie</a></p>
              </div>
              <div class="buttonwrap">

                <HaefeleButton
                  @click="requestLiveChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <img class='webflow_05-3--image' src="~assets/images/Akademie/12_Anmeldung-Screen@2x.png" alt="">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

      </transition>
    </div>
    <div class="menu__sidenav">
      <div v-for="i in cards.length" :key="i" class="menu__point">
        <span @click="paginateMenu(i)" :class="['square', {active: active === i}]"></span>
        <span v-if="i != cards.length" class="line"></span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import HaefeleButton from '@/components/shared/Button';

export default {
  components: {
    HaefeleButton
  },
  props: {
    showPopup: Boolean,
    closeProduct: Function,
    openPopup: Function,
    productId: String,
    inWindow: Boolean,
    toggleShowChat: Function,
    setSelectedUser: Function,
    selectedNote: String,
    setSelectedExhibit: Function,
    goToNotes: Function,
    toggleShowProduct: Function
  },
  data: () => ({
    exhibit: null,
    active: 1,
    showOverview: false,
    cards: [
      {
        type: 'webflow_01',
        headline_top: 'Der neue Service von Häfele',
        headline: 'HÄFELE AKADEMIE – <br /> TRAININGSPROGRAMM 2020.'
      },
      {
        type: 'webflow_01-0',
        headline_top: 'Trainingsprogramm 2020',
        headline: 'Beleuchtung <br/>Ganzheitlich verstehen.'
      },
      {
        type: 'webflow_01-1',
        headline_top: 'Praktisches Fachwissen für Schreiner/Tischler',
        headline: 'LICHT INSZENIERT RÄUME UND <br/> MACHT MÖBEL KOMFORTABEL.',
        description: 'Licht im Möbel hat heute wesentliche Funktionen als Teil der Innenraumgestaltung. Sei es das gezielte Ausleuchten von Funktionszonen oder die atmosphärische Aufwertung einzelner Wohnbereiche. Die Seminare der Häfele Akademie bieten dem Schreiner/Tischler die Möglichkeit sich im Kompetenzfeld "Licht" intensiver zu profilieren, sie vermitteln die zentralen Aspekte von Licht in Möbel und Raum in professionell aufgebauten Seminar-Modulen.'
      },
      {
        type: 'webflow_01-2',
        headline_1: 'Wo finden die Seminare statt?',
        headline_2: 'Für wen sind die Seminare?',
        headline_3: 'Wie viele Personen können teilnehmen?',
        description_1: 'Die Seminare werden im Licht Trainingscenter der Häfele Akademie in Stuttgart durchgeführt.',
        description_2: 'Die Lichtseminare sind inhaltlich für alle Beteiligten in einem Tischler-/Schreiner-Betrieb ausgelegt, die im täglichen Geschäft mit Beleuchtung umgehen müssen, sei es in der Beratung, in der Gestaltung, in der Planung oder im eigentlichen Möbelbau. Daher sind alle hier angebotenen Seminare ideal für Geschäftsführer, Verkäufer, Einkäufer, Designer/Planer, Projektleiter, Arbeitsvorbereitung und Werkstatt-/Monteure.',
        description_3: 'Um eine intensive und praxisnahe Vermittlung der Seminarinhalte für jeden Teilnehmer gewährleisten zu können, führt Häfele jedes Seminar mit maximal 12 Personen durch.'
      },
      {
        type: 'webflow_05',
        headline: 'Die Schulungsmodule',
        description:'Rund um das Thema Licht kann man jede Menge Wissen aufbauen. Für das Handwerk hat Häfele das erforderliche Know-how mit Blick auf die praktische Anwendung im Tischler- / Schreinerbetrieb in vier Modulen gebündelt. Diese können im Block oder einzeln besucht werden.',
        image_description_1:'Licht als gestalterisches Element für Möbel und Einrichtungen.',
        image_description_2:'Grundlagen der Lichttechnik.',
        image_description_3:'Lichtsteuerung und Vernetzung von analog bis digital.',
        image_description_4:'Fachgerechte Installation der Lichtkomponenten im Möbel.'
      },
      {
        type: 'webflow_05-1',
        headline: 'Die Schulungsmodule',
        description:'Rund um das Thema Licht kann man jede Menge Wissen aufbauen. Für das Handwerk hat Häfele das erforderliche Know-how mit Blick auf die praktische Anwendung im Tischler- / Schreinerbetrieb in vier Modulen gebündelt. Diese können im Block oder einzeln besucht werden.',
        tag:'GRUNDLAGEN ZU ALLEN THEMEN ALS KOMPAKTSEMINAR AN EINEM TAG.',
        image_description_1:'Licht als gestalterisches Element für Möbel und Einrichtungen.',
        image_description_2:'Grundlagen der Lichttechnik.',
        image_description_3:'Lichtsteuerung und Vernetzung von analog bis digital.',
        image_description_4:'Fachgerechte Installation der Lichtkomponenten im Möbel.'
      },

      {
        type: 'webflow_07',
        headline_top: 'Intensiv Kurs',
        headline: 'LICHTWIRKUNG & LICHTDESIGN.',
        description: 'LICHT ALS GESTALTERISCHES ELEMENT FÜR MÖBEL UND EINRICHTUNGEN',
        courseType: 'Präsenztraining',
        duration: '1 Tag'
      },
      {
        type: 'webflow_07-1',
        headline_top: 'Intensiv Kurs',
        headline: 'LICHTTECHNIK.',
        description: 'GRUNDLAGEN DER LICHTTECHNIK',
        courseType: 'Präsenztraining/Workshop',
        duration: '1 Tag'
      },
      {
        type: 'webflow_07-2',
        headline_top: 'Intensiv Kurs',
        headline: 'STEUERUNG UND VERNETZUNG.',
        description: 'LICHTSTEUERUNG UND VERNETZUNG VON ANALOG BIS DIGITAL',
        courseType: 'Präsenztraining/Workshop',
        duration: '1 Tag'
      },
      {
        type: 'webflow_07-3',
        headline_top: 'Intensiv Kurs',
        headline: 'LICHTINSTALLATION.',
        description: 'FACHGERECHTE INSTALLATION DER LICHTKOMPONENTEN IM MÖBEL',
        courseType: 'Präsenztraining',
        duration: '1 Tag'
      },
      {
        type: 'webflow_05-2',
        headline: 'KOMBI - ANGEBOT.',
        tag:'DAS SPEZIELLE ANGEBOT FÜR ALLE, DIE ALLES ÜBER BELEUCHTUNG WISSEN WOLLEN, 4 MODULE BUCHEN, 3 MODULE BEZAHLEN'
      },
      {
        type: 'webflow_05-3',
        headline: 'ANMELDUNG',
        description:'Beleuchtung ganzheitlich verstehen. Nutzen Sie die Möglichkeiten der Häfele Akademie und melden Sie sich gleich heute über das Online-Anmeldeformular für ein oder mehrere Lichtmodule an.',
        points:[
          'kann die Technischen Datenblätter lesen und deren Inhalte verstehen',
          'kennt die unterschiedlichen Beleuchtungssysteme und deren Vor- und Nachteile',
          'versteht die Qualitätsmerkmale der Beleuchtungssysteme und ist über die Vorschriften, Prüfzeichen und Normen'
        ]
      }
    ]
  }),
  computed: {
    activeCard() {
      return this.cards[this.active - 1];
    }
  },
  created() {
    window.addEventListener(
      'wheel',
      _.debounce(this.handleScroll, 100, {
        leading: true,
        trailing: false
      })
    );
  },
  destroyed() {
    window.removeEventListener('wheel', this.handleScroll);
  },
  mounted() {
    this.getExhibit();
  },
  methods: {
    async getExhibit() {
      var url = (await this.$store.getters.isAuthorized)
        ? '/exhibit'
        : '/shared-exhibit';
      this.$nextTick(async () => {
        let resp = await this.$http.get(url, {
          params: { uuid: this.productId }
        });
        this.exhibit = resp.data;
        this.files = this.exhibit.files;
        if (this.files) {
          this.images = this.files.images;
          this.videos = this.files.videos;
        }
        this.$store.getters.isAuthorized ? this.getNotes() : '';
      });
    },
    getNotes() {
      this.$http
        .get('/notes')
        .then(resp => {
          this.checkFavorites(resp.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    openNotes() {
      this.closeProduct();
      this.setSelectedExhibit(this.exhibit);
      this.goToNotes();
    },
    handleScroll(event) {
      if (this.$store.state.popup) {
        return;
      }
      console.log(this.cards.length, this.active);
      if (this.active === this.cards.length && event.deltaY > 0) {
        this.active = 0;
      }
      if (this.active === 1 && event.deltaY < 0) {
        this.active = this.cards.length + 1;
      }
      if (event.deltaY > 0) {
        this.active += 1;
      }
      if (event.deltaY < 0) {
        this.active -= 1;
      }
    },
    paginateMenu(i) {
      this.active = i;
    },
    async goToChat() {
      this.closeProduct();
      if (this.$store.getters.isAuthorized) {
        if (
          localStorage.getItem('user-roles').includes('exhibitor') ||
          localStorage.getItem('user-roles').includes('organizer')
        ) {
          this.$router.push('/exhibitor/' + this.exhibit.contact);
        } else {
          await this.$http.post(
            'distributor-call',
            JSON.stringify({ customer: this.$store.state.user.uuid })
          );
          this.setSelectedUser(this.exhibit.contact);
          this.toggleShowChat();
        }
      } else {
        window.location.assign(
          `mailto:${this.exhibit.contact}?Subject=${this.exhibit.title}`
        );
      }
    },
    async requestLiveChat() {
      this.closeProduct();
      if (this.$store.getters.isAuthorized) {
        if (
          localStorage.getItem('user-roles').includes('exhibitor') ||
          localStorage.getItem('user-roles').includes('organizer')
        ) {
          this.$router.push('/exhibitor/' + this.exhibit.contact);
        } else {
          await this.$http.post(
            'distributor-call',
            JSON.stringify({ customer: this.$store.state.user.uuid })
          );
          this.$store.state.modalMessage = 'Chat wurde angefragt. Ein Mitarbeiter meldet sich in Kürze.';
          this.$store.state.modalIcon = 'fas fa-check-circle';
          this.$store.state.modalClass = 'green-text';
          this.$store.state.modal = true;
          setTimeout(
            function() {
              this.$store.state.modal = false;
            }.bind(this),
            3000
          );
        }
      } else {
        window.location.assign(
          `mailto:${this.exhibit.contact}?Subject=${this.exhibit.title}`
        );
      }
    },
    menuToggle() {
      this.showOverview = true;
    }
  }
};
</script>

<style scoped lang="scss">

  .vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
  }
  .vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
  }
  @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
  }
  @keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
  }

.menu-wrapper {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


.scroll-down {
  position: absolute;
  bottom: 2%;
  left: 50%;
  z-index: 99;

  &:hover {
    cursor: pointer;
  }
}
.scroll-up {
  position: absolute;
  top: 2%;
  left: 50%;
  z-index: 99;

  &:hover {
    cursor: pointer;
  }
}

.btn{
  margin-left: 0 !important;
}

.mockup {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.laptop_wrapper {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.preview-video {
  position: absolute;
  right: 7%;
  bottom: 6%;
  padding: 20px 45px 58px 15px;
  max-width: 883px;

  @include respond(laptop) {
    padding: 20px 49px 79px 31px;
    bottom: 8%;
    left:9%;
  }

  @include respond(laptop-small) {
    padding: 20px 40px 63px 21px;
    bottom: 14%;
  }
}

.angebot{
  position: absolute;
  right: 90px;
  bottom: 20px;

  display:flex;
  align-items: flex-end;

  img{
    height: 60px;
    padding-left: 20px;
  }

  div p{
    margin-bottom: 0;
    padding-left: 20px;
  }
}

h1{
  font-size: 26px;

  @include respond(laptop){
    font-size: 22px;
  }
}

.modal_deep_dive {
  position: relative;
  width: 85vw;
  height: 75vh;
  color: white;
  background: #505050e6;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #bcbcbc;
  }
}

.modal_deep_dive--bgImage{
  background-image: url('~assets/images/Akademie/01_Akademie_Allgemein.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fullscreen-bg-02 {
  background-image: url('~assets/images/Akademie/02_Akademie_Licht.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fullscreen-bg-03 {
  background-image: url('~assets/images/Akademie/03_Intro_01.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fullscreen-bg-04 {
  background-image: url('~assets/images/Akademie/03_Intro_01.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  }
.fullscreen-bg-09 {
  background-image: url('~assets/images/Slido/09_Trennseite_ Faltschieben@2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.fullscreen-bg-11 {
  background-image: url('~assets/images/Slido/11_Tennseite_Drehschieben@2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
  .modal_deep_dive_06{
    background-image: url("~assets/images/08_WebFlow_08.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

.modal_deep_dive_video {
  position: relative;
  overflow: hidden;
  width: 85vw;
  height: 75vh;
  color: white;
  background: #505050aa;
  overflow:hidden;
  
  .left_side {
    padding: 40px;
    padding-right: 0;
  }
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #bcbcbc;

    @include respond(laptop-small) {
      font-size: 14px;
    }
  }
}

.point{
  font-family: 'Roboto';

  &__arrow{
    color: $secondaryColor;
  }
}

.buttonwrap {
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 87%;

  // @include respond(laptop){
  //   width: 66%;
  // }
}

.button {
  padding: 5px;
  text-align: left;
  max-width: 328px;
}

.webflow_05-3--image{
  width: 70%;
}

.webflow_01{
  &--left_side{
    width: 40vw;


    .left_side--top{
      background-color: rgba(0, 0, 0, .75);
      padding: 40px 100px 40px 40px;
      margin-bottom: 70px;
    }

    .buttonwrap{
      padding-left: 40px;
      width: 60%;
    }
  }
}

.webflow_01-1{
  background-color: rgba(0, 0, 0, .75);
  padding: 40px 100px 40px 40px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  align-items: flex-start !important;

    &--grid{
        @include display-grid($gap: 40px);
        justify-content: space-between !important;
    }

    .right_side--top{
      width: 30%;
      margin-left:-50px;
    }

    .center--top{
      width:30%;
    }

    .left_side--top{
      width: 30%
    }

    &--button{
        height: 100%;
        @include display-grid($gap: 35px);
        align-items: flex-start !important;
        width: 100%;

      .buttonwrap{
        padding-left: 40px;
        justify-self: flex-start !important;
      }
    }
    .left_side {
      @include display-column(center);
      justify-content: space-between;

      p{
        font-size: 14px;
      }

      .webflow_01--icon{
        width: 40%;
      }
    }
    
}

.webflow_02 {
  height: 100%;
  @include display-grid($gap: 35px);

  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    font-size: 26px;
    font-weight: 300;
    font-family: 'Roboto' sans-serif;
    text-transform: uppercase;
    text-align: left;
    @include respond(laptop-small) {
      font-size: 22px;
    }

  }
  .left_side {
    @include display-column(center);
    justify-content: space-between;

    p{
      font-size: 15px;
    }

    .webflow_02--icon{
      width: 100%;
    }
  }

  .image-slug{
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    padding-top: 15px;
    width: 90%;
    height: 70px;
  }

  .card__headline--right{
    text-align: left;
  }

  &--img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 90%;
    }
  }
}
.webflow_01 {
  height: 100%;
  @include display-grid($gap: 35px);
  align-items: flex-start;
  margin-top:50px;

  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    font-size: 26px;
    font-weight: 300;
    font-family: 'Roboto' sans-serif;
    // text-transform: uppercase;
    text-align: left;
    @include respond(laptop-small) {
      font-size: 22px;
    }

  }
  .left_side {
    @include display-column(center);
    justify-content: space-between;

    p{
      font-size: 15px;
    }

    .webflow_01--icon{
      width: 40%;
    }
  }

  .image-slug{
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    padding-top: 15px;
    width: 90%;
    height: 70px;
  }

  .card__headline--right{
    text-align: left;
  }

  &--img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 90%;
    }
  }
}
.webflow_01-1 {
  height: 100%;
  display: flex;
  flex-direction: row;

  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    font-size: 26px;
    font-weight: 300;
    font-family: 'Roboto' sans-serif;
    // text-transform: uppercase;
    text-align: left;
    @include respond(laptop-small) {
      font-size: 22px;
    }

  }
  .left_side {
    @include display-column(center);
    justify-content: space-between;

    p{
      font-size: 15px;
    }

    .webflow_01--icon{
      width: 40%;
    }
  }

  .image-slug{
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    padding-top: 15px;
    width: 90%;
    height: 70px;
  }

  .card__headline--right{
    text-align: left;
  }

  &--img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 90%;
    }
  }
}

.webflow_03 {
  display: flex;
  height: 100%;
  .left_side {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond(laptop) {
      width: 40%;
    }
  }
  .right_side {
    position: relative;
    width: 70%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    text-transform: uppercase;
  }
  .point {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #888888;
      font-family: "Roboto", sans-serif;
      text-align: start;
      margin-top: -35px;

      @include respond(laptop) {
        margin-top: -25px;
        font-size: 40px;
      }
    }
  }
}

.webflow_04 {
  display: flex;
  position: relative;
  height: 100%;
  .left_side {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right_side {
    position: relative;
    width: 70%;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }
  }

  &--video {
    position: absolute;
    top: -12%;
    right: 32%;
    width: 100%;
    height: 100%;
    z-index: -1;

    video {
      width: 150%;
      height: 150%;

      @include respond(laptop-small) {
        width: 120%;
        height: 120%;
      }
    }

    @include respond(laptop) {
      top: -10%;
      right: 27%;
    }

    @include respond(laptop-small) {
      top: 0%;
      right: 0%;
    }
  }
}
.webflow_05 {
  display: flex;
  position: relative;
  height: 100%;
  .left_side {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 80px;

    p{
      font-size: 15px;
    }

    .webflow_05--icon{
      width: 100%;
    }
  }

  .image-slug{
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    padding-top: 15px;
    width: 90%;
    height: 70px;
  }

  .images__grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 35px;

    &--image{
      width: 350px;
      
      @include respond(laptop-small){
        width: 280px;
      }
    }

    p{
      max-width: 350px;
      margin-bottom: 0;

      @include respond(laptop-small){
        width: 280px;
      }
    }
  }

  .card__headline--right{
    text-align: left;
  }

  &--img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 90%;
    }
  }

  .right_side {
    position: relative;
    width: 70%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }
  }

  &--video {
    position: absolute;
    top: -12%;
    right: 32%;
    width: 100%;
    height: 100%;
    z-index: -1;

    video {
      width: 150%;
      height: 150%;

      @include respond(laptop-small) {
        width: 120%;
        height: 120%;
      }
    }

    @include respond(laptop) {
      top: -10%;
      right: 27%;
    }

    @include respond(laptop-small) {
      top: 0%;
      right: 0%;
    }
  }
}

.webflow_06 {
  display: flex;
  position: relative;
  height: 100%;

  .left_side {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right_side {
    position: relative;
    width: 49%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    img{
      height:80%;
    }
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }
  }
}

.webflow_07 {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;

  &--image{
    width: 100%;
  }

  &--details{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;

    .details__column{
        flex-direction: column;
        align-items: flex-start;
        width: 50%;

        p{
          margin-bottom: 0;
        }
    }
  }
  .left_side {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;

    p{
      margin-bottom: 0;
    }
    .point{
      margin-top: -15px;
      &__text{
        padding-bottom: 20px;
      }
      @include respond(laptop){
        margin-top: -15px;
        &__text{
          padding-bottom: 45px;
        }
      }
    }
  }
  .middle__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30%;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }

    .middle__list{
      margin-bottom: 20px;
      &--point{
      display: flex;
      flex-direction: row;
      align-items:flex-start;
      justify-content: flex-start;
      width:90%;

        p{
          font-size: 12px;
          margin-bottom: 0;
        }
        span{
          padding-right: 10px;
        }
      }
    }
  .right_side {
    position: relative;
    width: 33%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 40px; 
    padding-left: 10px;


    .point{ 
      margin-top: 30px;

      img{
        margin-top: 20px;
      }
      span{
        line-height: 30px;
        font-size: 32px;
        text-align: right;
      }
    }
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__text{
      display:flex;
      align-items: baseline;
    }
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }

    img{
      width: 50%;
    }
  }
}
.webflow_08 {
  display: flex;
  position: relative;
  height: 100%;
  .left_side {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;

    .point{
      // margin-top: -15px;
      &__text{
        padding-bottom: 20px;
      }
      @include respond(laptop){
        // margin-top: -15px;
        &__text{
          // padding-bottom: 45px;
        }
      }
    }
  }
  .middle__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;

    img{
      height: 60px;
    }
  }
  .right_side {
    position: relative;
    width: 25%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 40px; 
    padding-left: 10px;


    .point{ 
      // margin-top: 30px;

      img{
        height: 60px;
      }
    }
  }
  .right_side_2 {
    position: relative;
    width: 25%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 40px; 
    padding-left: 10px;


    .point{ 
      margin-top: 45px;

      img{
        height: 60px;
      }
    }
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    font-size: 28px;
  }
  .point {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__text{
      display:flex;
      align-items: baseline;
    }
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }

    img{
      height: 60px;
    }
  }
}

.menu {
  &__point {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__sidenav {
    margin: 0 50px 0 0;
    .line {
      height: 20px;
      width: 2px;
      background: white;

      @include respond(laptop){
        height: 15px;
      }
    }
    .square {
      margin: 10px 0;
      height: 15px;
      width: 15px;
      border: 1px solid white;
      cursor: pointer;

      @include respond(laptop){
        height: 15px;
        width: 15px;
      }

      &__overview {
        height: 9px;
        width: 9px;
        border: 1px solid white;
        cursor: pointer;
      }
    }
    .active {
      background: white;
    }
  }

  &__overview {
    width: 20px;
    height: 20px;
    display: grid;
    gap: 2px;
    grid-template-columns: 1fr 1fr;

    padding-bottom: 100px;
  }
}

.row {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
  transform: scale(1);
  -ms-transform: scale(1); /* IE 9 */
  -moz-transform: scale(1); /* Firefox */
  -webkit-transform: scale(1); /* Safari and Chrome */
  -o-transform: scale(1);
}
.fade-enter {
  opacity: 0;
  transform: scale(0);
}

.fade-leave-to {
  opacity: 0;
  transform: scale(2) translate(0px, 0px);
  -ms-transform: scale(2) translate(0px, 0px); /* IE 9 */
  -moz-transform: scale(2) translate(0px, 0px); /* Firefox */
  -webkit-transform: scale(2) translate(0px, 0px); /* Safari and Chrome */
  -o-transform: scale(2) translate(0px, 0px);
}
</style>
